@import '../../../assets/css/common.scss';

.projectBox {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  & > div {
    flex: 1 0 25%;
    display: flex;
    justify-content: center;
    align-items: center;

    & .projectDesc {
      width: 100%;
      margin: 20px;
      position: relative;

      & img {
        width: 100%;
      }

      & .projectDetail {
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: get_color(black);
        opacity: 0.6;
        padding: 10px;

        & h2 {
          font-size: 12px;
          color: get_color(white);
          line-height: 12px;
        }

        & p {
          font-size: 10px;
          color: get_color(white);
          line-height: 16px;
        }
      }
    }
  }
}