@import '../../../assets/css/common.scss';

.enquire {
  display: block;

  & .formBox {
    display: flex;
    width: 40%;
    flex-direction: column;

    @media (max-width: $breakpoint-tablet) {
      width: 100%;
    }

    & .formItem {
      flex: 1 0 100%;
      margin-bottom: 20px;

      & label {
        display: block;
        color: get_color(nav_menu_font_color);
        font-size: 14px;
        padding-left: 0;
        padding-bottom: 4px;
      }

      & input, & textarea {
        display: block;
        width: 100%;
        padding: 10px 0;
        margin: 0;
        border: 1px solid get_color(gray);
        background-color: get_color(white_1);
        font-size: 18px;
      }

      & button {
        padding: 10px 30px;
        margin: 0;
        border: 0;
        font-size: 14px;
        border: 2px solid get_color(nav_menu_font_color);
        background-color: get_color(white);
        font-weight: 600;
        font-style: normal;
        text-transform: uppercase;
        letter-spacing: .1em;
        transition: 0.1s background-color linear, 0.1s color linear;
        color: get_color(nav_menu_font_color);

        &:hover {
          background-color: get_color(nav_menu_font_color);
          color: get_color(white);
        }
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }
}