@import '../../../assets/css/common.scss';

.bannerBox {
  display: block;
  position: relative;

  @media (max-width: $breakpoint-tablet) {
    height: 300px;
  }

  @media (min-width: $breakpoint-tablet) {
    height: 600px;
  }

  & .siteCss {
    position: static;
    max-width: $site-max-width;
    padding: $site-padding;
    margin: $site-margin;
  }

  & .bannerItem {
    display: none;
    opacity: 0;

    & .slideItem {
      position: relative;
      background-repeat: no-repeat;
      background-position: center top;
      background-size: cover;
      padding: 450px 0 155px;
      overflow: hidden;
      width: 100%;
      background-size: 100% 300px;

      @media (max-width: $breakpoint-tablet) {
        background-size: 100% 300px;
      }
  
      @media (min-width: $breakpoint-tablet) {
        background-size: 100% 600px;
      }
    }
  
    .contentBox {
      position: relative;
      z-index: 2;
      color: get_color(white);
    }

    &.active {
      display: block;
      opacity: 1;
      transition: background-image 0.2s ease-out opacity 0.1s ease-out display 0.3s;
    }
  }
}