@import '../../../assets/css/common.scss';

.projectDescBox {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  & > div {
    flex: 1 0 50%;
    display: flex;
    flex-direction: column;
    @media (max-width: $breakpoint-tablet) {
      flex: 1 0 100%;
    }

    & .images {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      & > div {
        flex: 1 0 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: $breakpoint-tablet) {
          flex: 1 0 100%;
        }

        & .projectDesc {
          box-shadow: 3px 3px 4px 3px get_color(white_font_color_light);
          cursor: pointer;
        }

        & img {
          display: block;

          @media (min-width: $breakpoint-tablet) {
            height: 100px;
          }
          @media (max-width: $breakpoint-tablet) {
            min-height: 100px;
          }
        }
      }
    }
  }
}

.imgModal {
  display: block;

  & .current {
    display: block;
    width: 100%;

    & img {
      width: 100%;
    }
  }

  & .list {
    display: block;
    overflow: hidden;
    position: relative;

    & div {
      position: absolute;
      top: 0;
      z-index: 1;
      background-color: get_color(black);
      padding: 10px;
      height: 80px;
      opacity: 0.7;
      cursor: pointer;

      &.left {
        left: 0;
      }
  
      &.right {
        right: 0;
      }

      & svg {
        z-index: 1;
        color: #fff;
        margin-top: 35px;
      }
    }

    & ul {
      display: block;
      width: 1500px;
      height: 100px;
  
      & li {
        display: inline-block;
        margin-right: 10px;
        height: 100px;

        &:last-child {
          margin-right: 0;
        }
  
        & img {
          width: 100px;
          height: 100px;
        }
      }
    }
  }
}