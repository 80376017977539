@import '../../../assets/css/common.scss';

@media (max-width: $breakpoint-tablet) {
  .offer {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    margin: 0;

    & > section, & > figcaption {
      width: 100%;
      float: left;
      flex: 1 0 50%;
    }

    & aside .w-full {
      padding: 1rem;
    }

    & > aside {
      box-shadow: 0 2px 4px 0 #000000;
      background-color: #ffffff;
      float: left;
      width: 100%;
      margin-bottom: 50px;
      margin-top: 50px;
    }

    & aside .flex nav {
      width: 48%;
    }
  
    & aside .flex section {
      width: 50%;
      margin-left: 2%;
  
      & > article {
        flex-direction: column;
      }
    }
  }
}

@media (min-width: $breakpoint-tablet) {
  .offer {
    display: block;
    position: relative;
    flex: 1 1 50%;
    margin: 0;

    &:first-child {
      padding-right: 20px;

      & > aside {
        margin: 50px 0;
      }
    }

    &:last-child {
      padding-left: 20px;

      & > aside {
        margin: 50px 0;
      }
    }

    & > section, .offer > figcaption {
      width: 50%;
      float: left;
    }
  
    & > aside .w-full {
      width: 100%;
    }

    & > .clear {
      clear: both;
    }
  
    & > aside {
      height: 420px;
      box-shadow: 0 2px 4px 0 #000000;
      background-color: #ffffff;
      padding: 2.5rem;
      padding-bottom: 0;
      display: block;
    }
  
    & aside .flex nav, 
    & aside .flex section {
      width: 50%;
    }
  }
}

.offer section:first-child img:first-child {
  width: 8rem;
  width: 80%;
}

.offer aside .flex {
  display: flex;
}

.navBar {
  display: block;
}

.navBar > p {
  background-repeat: no-repeat;
  background-size: 20px 20px;
  height: 36px;
  line-height: 20px;
}

.navBar > p.active {
  padding-left: 40px;
}

.rightbox {
  display: block;
}

.rightbox img {
  width: 76px;
  height: 45px;
}

.rightbox ul {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
}

.rightbox ul li{
  display: block;
  margin: 0;
  padding: 0.5rem 0;
}