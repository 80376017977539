@import '../../../assets/css/common.scss';

.mainHeader {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  background-color: get_color(white);

  & .topHeader {
    display: block;
    
    & .contactInfo {
      display: flex;
      margin: 0;
      list-style: none;

      @media (max-width: $breakpoint-tablet) {
        padding: 4px 0;
      }

      @media (min-width: $breakpoint-tablet) {
        float: right;
        padding: 10px 0;
      }

      & > li {
        margin: 0;
        padding: 0;

        &:last-child {
          text-align: right;
          margin-left: 20px;
        }

        & span {
          font-weight: 600;
          color: get-color(white_font_color);
          font-size: 10px;
        }

        & a {
          color: get-color(white_font_color);
          font-size: 8px;
          text-decoration: none;
          font-weight: 400;
          font-style: normal;
        }

        @media (max-width: $breakpoint-tablet) {
          & {
            flex: 0 0 50%;

            &:last-child {
              margin-left: 0;
            }
          }

          & span {
            font-size: 10px;
          }

          & a {
            font-size: 10px;
          }
        }

        @media (min-width: $breakpoint-tablet) {
          & span {
            font-size: 12px;
          }

          & a {
            font-size: 12px;
          }
        }

        &:last-child {
          float: right;
        }
      }
    }
  }

  & > .lowerHeader {
    display: block;
    position: relative;

    @media (min-width: $breakpoint-tablet) {
      margin: 20px 0;
    }

    & .navBar {
      display: flex;
      width: 100%;

      & .navbar-header {
        display: none;
      }

      @media (max-width: $breakpoint-tablet) {
        & .navbar-header {
          float: right;
          display: block;

          & button {
            cursor: pointer;
            border: 0;
            padding: 15px 0;
            background: transparent;

            & svg {
              height: 30px;
              width: 30px;
            }
          }
        }
      }

      & .logo-box {
        flex: 0 0 26%;
        padding: 16px 0 0 0;

        @media (max-width: $breakpoint-tablet) {
          flex: 0 0 90%;
          padding: 15px 0;
          height: 32px;
        }
        
        & a {
          text-decoration: none;
          color: get_color(white_font_color);
        }
      }
    }

    & .navMenu {
      flex: 1;
      
      @media (max-width: $breakpoint-tablet) {
        & .navigation {
          display: none;
          margin: 0;
          padding: 0;
          clear: both;
          position: absolute;
          left: 0;
          right: 0;
          top: 62px;
          background-color: get_color(nav_menu_font_color_hover);
          z-index: 4;

          & ul {
            display: block;
            margin: 0 10px;
            padding: 0;
            clear: both;
            top: 62px;
            background-color: get_color(nav_menu_font_color_hover);
            z-index: 1;
          }

          & li {
            margin: 0;
            padding: 0;
            display: block;

            & a {
              color: get_color(white);
              padding: 10px 15px;
              text-decoration: none;
              display: block;

              &:hover {
                color: get_color(red);
              }
            }

            &:last-child a {
              padding-right: 0;
            }
          }

          &.open {
            display: block;
          }
        }
      }

      @media (min-width: $breakpoint-tablet) {
        & .navigation {
          margin: 0;
          padding: 0;
          list-style: none;
          float: right;

          & ul {
            display: none;
          }

          & > li {
            margin: 0;
            display: inline-block;
            padding: 25px 15px;

            & > a {
              display: block;
              color: get_color(nav_menu_font_color);
              font-size: 14px;
              text-decoration: none;
              font-weight: 400;
              font-style: normal;
              text-transform: uppercase;
              letter-spacing: .1em;
              border-bottom: 1px solid transparent;
              padding: 0 0 2px 0;

              &:hover, &.active {
                color: get_color(nav_menu_font_color_hover);
                border-color: get_color(nav_menu_font_color_hover);
                -webkit-transition: border 0s ease-out;
                -moz-transition: border 0s ease-out;
                -o-transition: border 0s ease-out;
                transition: border 0s ease-out;
              }
            }

            &:last-child {
              padding-right: 0;
            }
          }
        }
      }
    }
  }
}